import React, { useState, useEffect } from 'react';
import { message, Checkbox, Table, Input, Button, Tag, Tooltip, Modal, Form, Radio } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import axios from 'axios';
import './TableComponent.css';
import _ from 'lodash';

interface DataType {
    key: number;
    No: number;
    Address: string;
    HoldPercent: string;
    AvgBuyPrice: number;
    WeightedAverageCost: number;
    FirstPurchaseTime: string;
    BSRatio: string;
    TotalPnl: string;
    SolSource: string;
    SplSource: string;
    BuySolAmt: string;
    SelSolAmt: string;
    New: boolean;
    Dev: boolean;
    MouseAddress: boolean;
    Bruiser: boolean;
    PotentialAbsorb: boolean;
    AddressTag: string;
    NewMouseAddress: boolean;
    TxRelative: boolean;
    TransferInAmount: string;
    TransferOutAmount: string;
    NetTransferredAmount: string;
    TraceableAveragePrice: number;
    SmartMoney: boolean;
    ROI: string;
    PoolTag: boolean;
    PoolName: string;
    SolSourceTag: string;
}

interface TableComponentProps {
    setLoading: (loading: boolean) => void;
}

interface GroupType {
    id: string;
    addresses: string[];
    tag: string;
}

const apiUrl = process.env.REACT_APP_API_URL;
// const authToken = localStorage.getItem('authToken');  // 从 localStorage 获取存储的 authToken
const TableComponent: React.FC<TableComponentProps> = ({ setLoading }) => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [address, setAddress] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // 控制错误提示
    const [newNum, setNewNum] = useState<number>(0);
    const [totalNum, setTotalNum] = useState<number>(0);
    const [newPercent, setNewPercent] = useState<number>(0);
    const [insiderNum, setInsiderNum] = useState<number>(0);
    const [smartWalletNum, setSmartWalletNum] = useState<number>(0);
    const [insiderPercent, setInsiderPercent] = useState<number>(0);
    const [smartWalletPercent, setSmartWalletPercent] = useState<number>(0);
    const [totalPercent, setTotalPercent] = useState<number>(0);
    const [solSourceColors, setSolSourceColors] = useState<{ [key: string]: string }>({});
    const [splSourceColors, setSplSourceColors] = useState<{ [key: string]: string }>({});
    const [hideSingleSolSource, setHideSingleSolSource] = useState<boolean>(false);
    const [hideSingleSplSource, setHideSingleSplSource] = useState<boolean>(false);
    const [isSortedByPnl, setIsSortedByPnl] = useState<boolean>(false); // 新增状态变量
    const [isSortedByROI, setIsSortedByROI] = useState<boolean>(false); // 新增状态变量
    const [originalDataSource, setOriginalDataSource] = useState<DataType[]>([]); // 保存原始数据
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const [tokenAddress, setTokenAddress] = useState('');
    const [targetAddress, setTargetAddress] = useState('');
    const [remark, setRemark] = useState('');
    const [ignoreNew, setIgnoreNew] = useState(false);
    const [ignoreTx, setIgnoreTx] = useState(false);
    const [ignoreMouse, setIgnoreMouse] = useState(false);
    const [ignoreSolSource, setIgnoreSolSource] = useState(false);
    const [ignoreSplSource, setIgnoreSplSource] = useState(false);
    const [ignoreAll, setIgnoreAll] = useState(false);
    const [ignoreAllTags, setIgnoreAllTags] = useState(false);
    const [ignoreAllSources, setIgnoreAllSources] = useState(false);

    const [token, setToken] = useState<string | null>(localStorage.getItem('authToken'));
    const [isModalVisible2, setIsModalVisible2] = useState<boolean>(!token);
    const [inputToken, setInputToken] = useState<string>('');
    const [tokenNAme, setTokenName] = useState<string>('');

    const [blackListType, setBlackListType] = useState<string>('exchange');
    // const [isModalVisible2, setIsModalVisible2] = useState(false);

    const [isGroupModalVisible, setIsGroupModalVisible] = useState(false);
    const [isAddGroupModalVisible, setIsAddGroupModalVisible] = useState(false);
    const [groupList, setGroupList] = useState<GroupType[]>([]);
    const [currentGroup, setCurrentGroup] = useState<{
        addresses: string[];
        tag: string;
    }>({
        addresses: [''],
        tag: ''
    });

    // 添加新的 state
    const [groupStats, setGroupStats] = useState<Array<{
        groupName: string;
        addresses: string[];
        totalPercent: number;
    }>>([]);

    // 添加一个状态来跟踪是否处于编辑模式
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editingGroupId, setEditingGroupId] = useState<string>('');

    // 显示输入token的弹框
    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    // 关闭弹框并存储token
    const handleOk = () => {
        checkToken(inputToken);
    };

    // 弹框关闭操作
    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleAddToBlacklist = async () => {
        try {
            const blacklistParams = {
                targetAddress,
                ignoreNew,
                ignoreTx,
                ignoreMouse,
                ignoreSolSource,
                ignoreSplSource,
                blackListType,
                remark,
            };
            const response = await axios.post(`${apiUrl}/core/v1/addBlackList`, {
                blacklistParams
            }, {
                headers: {
                    'Auth-Key': localStorage.getItem('authToken'),
                },
            });

            if (response.status == 200) {
                message.success('黑名单添加成功');
                setIsModalVisible(false);
            }
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                setIsModalVisible(false);
                message.error('Token错误，请重新输入');
                localStorage.removeItem('authToken');
                showModal2();
            } else {
                message.error('黑名单添加失败，请稍后再试');
                console.error('Add error:', error);
            }
        }
    };

    // 上传符合条件的数据
    const handleUpload = async () => {
        const filteredData = dataSource.filter(record => record.New || record.NewMouseAddress || record.TxRelative);
        if (filteredData.length === 0) {
            message.warning('没有符合条件的数据');
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/core/v1/uploadFilteredData`, {
                tokenAddress: address, // 查询时传入的地址
                tokenName: tokenNAme,
                filteredData: filteredData.map(record => ({
                    New: record.New,
                    NewMouseAddress: record.NewMouseAddress,
                    TxRelative: record.TxRelative,
                    walletAddress: record.Address,
                    FirstPurchaseTime: record.FirstPurchaseTime,
                }))
            }, {
                headers: {
                    'Auth-Key': localStorage.getItem('authToken'),
                },
            });

            if (response.status === 200) {
                message.success('数据上传成功');
            }
        } catch (error) {
            message.error('数据上传失败');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const fetchData = async (address: string) => {
        setLoading(true); // 显示Loading
        setErrorMessage(null); // 重置错误消息
        try {
            const useMock = process.env.REACT_APP_USE_MOCK === 'true';
            let response;
            // 假数据
            if (useMock) {
                response = await axios.get('/mock-data/core/v1/tokenRetrieval.json');
            } else {
                // 调用后端接口
                response = await axios.get(`${apiUrl}/core/v1/tokenRetrieval`, {
                    params: { address },
                    headers: {
                        'Auth-Key': localStorage.getItem('authToken'),
                    },

                }

                )
            };


            // 如果接口返回结果为 null
            if (response.data === null) {
                setErrorMessage('超限,請等待1分鐘');
                setDataSource([]); // 确保表格清空
            } else {
                setNewNum(response.data.NewAddressAmount);
                setNewPercent(!isNaN(response.data.NewAddressPercentage) ? response.data.NewAddressPercentage : 0);
                setInsiderNum(response.data.DogAddressAmount);
                setSmartWalletNum(response.data.SmartMoneyAmount);
                setInsiderPercent(!isNaN(response.data.DogAddressPercentage) ? response.data.DogAddressPercentage : 0);
                setSmartWalletPercent(!isNaN(response.data.SmartMoneyPercentage) ? response.data.SmartMoneyPercentage : 0);
                setTotalPercent(response.data.TotalPercentage);
                const data = response.data.FinalData.map((item: any) => ({
                    key: item.No,
                    ...item,
                }));
                setTokenName(response.data.TokenName)
                if (response.data.groupStats) {
                    setGroupStats(response.data.groupStats);
                }
                setDataSource(data);
                setOriginalDataSource(data);
            }
        } catch (error: any) {
            setDataSource([]); // 确保表格清空
            if (error.response && error.response.status === 401) {
                message.error('Token错误，请重新输入');
                localStorage.removeItem('authToken');  // 删除无效token
                showModal2();  // 弹出输入框
            } else {
                setErrorMessage('报错:' + error.response.data);
                console.error('Fetch error:', error);
            }
        } finally {
            setLoading(false); // 隐藏Loading
        }
    };

    const checkToken = async (token: string) => {
        if (process.env.REACT_APP_ENV === "development") {
            setIsModalVisible2(false);
        } else {
            setLoading(true); // 显示Loading
            try {
                const response = await axios.get(`${apiUrl}/core/v1/checkToken`, {
                    headers: {
                        'Auth-Key': token,
                    },
                });
                // 如果接口成功返回，隐藏 Modal
                setIsModalVisible(false);
                localStorage.setItem('authToken', inputToken);
                setToken(inputToken);
                setIsModalVisible2(false);
                console.log('Token验证成功:', response.data);
            } catch (error: any) {
                // 如果接口返回结果为 null
                if (error.response && error.response.status === 401) {
                    message.error('Token错误，请重新输入');
                }
                console.error('Fetch error:', error);
            } finally {
                setLoading(false); // 隐藏Loading
            }
        }

    };

    // 金额简化科学计算法
    function formatNumber(input: any): string {
        // 确保输入是一个数字
        const num = Number(input);

        if (isNaN(num)) {
            throw new Error('Invalid number');
        }

        // 将科学计数法转换为普通数字表示，避免出现科学计数法格式
        const numStr = num.toFixed(20).replace(/\.?0+$/, ''); // 保留足够多的小数位并移除多余的0

        // 匹配以 0. 开头且小数点后有零的数字
        const match = numStr.match(/^0\.(0+)(\d+)/);

        if (match) {
            const zeroCount = match[1].length; // 获取小数点后零的个数
            const nonZeroPart = match[2]; // 获取去掉零后的部分

            if (zeroCount >= 2) {
                // 当零的个数大于等于2时，简化零的部分
                const formattedNumber = nonZeroPart.slice(0, 3); // 取非零部分的三位有效数字
                return `0.{${zeroCount}}${formattedNumber}`;
            }
        }

        // 对于非 0. 开头或小数点后没有多个零的数字，保留非零部分三位有效数字
        const [integerPart, decimalPart] = numStr.split('.');

        if (decimalPart) {
            const significantDigits = decimalPart.replace(/^0+/, '').slice(0, 3); // 去掉前导零并取三位有效数字
            return `${integerPart}.${decimalPart.slice(0, decimalPart.indexOf(significantDigits) + significantDigits.length)}`;
        }

        return numStr; // 整数部分直接返回
    }

    // 处理地址省略
    const formatAddress = (address: string) => {
        if (address.length >= 14) {
            return `${address.slice(0, 4)}...${address.slice(-4)}`;
        }
        return address; // 如果字符串长度小于8，则不进行省略
    };

    // 复制地址函数（带有手动复制的后备方案）
    const handleCopy = (address: string) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(address)
                .then(() => {
                    message.success('地址复制成功!');
                })
                .catch(() => {
                    message.error('复制失败，请重试!');
                });
        } else {
            // 创建一个隐藏的textarea元素来手动复制
            const textArea = document.createElement('textarea');
            textArea.value = address;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                message.success('地址复制成功!');
            } catch (err) {
                message.error('复制失败，请手动复制!');
            }
            document.body.removeChild(textArea);
        }
    };

    const handleSearch = () => {
        fetchData(address);
    };

    // 添加 useEffect 钩子来在初始化时请求数据
    useEffect(() => {
        if (!token) {
            showModal2();  // 如果没有 token，显示输入 token 的弹窗
        }
    }, [token]);  // 依赖 token，当 token 变化时重新执行此钩子

    useEffect(() => {
        let newNumCount = 0;
        let totalNumCount = 0;
        let newPercentSum = 0;
        let insiderNumCount = 0;
        let smartWalletCount = 0;
        let insiderPercentSum = 0;
        let smartWalletPercentSum = 0;
        let overlapPercentSum = 0;
        // 测试用计数器
        // let mouseAmount = 0;
        // let txAmount = 0;

        dataSource.forEach(record => {
            totalNumCount++;
            if (record.New) {
                newNumCount++;
                const newPercentStr: string = record.HoldPercent.replace("%", "");
                const newPercentFloat = parseFloat(newPercentStr);
                // 只处理有效数字，避免NaN
                if (!isNaN(newPercentFloat)) {
                    newPercentSum += newPercentFloat;
                }
            }

            // if (record.NewMouseAddress) {
            //     mouseAmount++;
            //     console.log("老鼠仓数量:" + record.Address)
            //     console.log("SPL来源:" + record.SplSource)
            // }

            // if (record.TxRelative) {
            //     txAmount++;
            // }

            if (record.New || record.TxRelative || record.NewMouseAddress) {
                insiderNumCount++;
                const walletPercentStr: string = record.HoldPercent.replace("%", "");
                const insiderPercentFloat = parseFloat(walletPercentStr);
                // 只处理有效数字，避免NaN
                if (!isNaN(insiderPercentFloat)) {
                    insiderPercentSum += insiderPercentFloat;
                    // 如果是聪明钱则计算交叠部分
                    if (record.SmartMoney) {
                        overlapPercentSum += insiderPercentFloat;
                    }
                }
            }

            if (record.SmartMoney) {
                smartWalletCount++;
                const walletPercentStr: string = record.HoldPercent.replace("%", "");
                const walletPercentFloat = parseFloat(walletPercentStr);
                // 只处理有效数字，避免NaN
                if (!isNaN(walletPercentFloat)) {
                    smartWalletPercentSum += walletPercentFloat;
                }
            }
        });

        // console.log("老鼠仓数量:" + mouseAmount)
        // console.log("tx关联数量:" + txAmount)
        // console.log("交叠占比:" + overlapPercentSum)
        // 前端变为后端计算
        // setNewNum(newNumCount);
        // setTotalNum(totalNumCount);
        // setNewPercent(!isNaN(newPercentSum) ? newPercentSum : 0);
        // setInsiderNum(insiderNumCount);
        // setSmartWalletNum(smartWalletCount);
        // setInsiderPercent(!isNaN(insiderPercentSum) ? insiderPercentSum : 0);
        // setSmartWalletPercent(!isNaN(smartWalletCount) ? smartWalletPercentSum : 0);
        // setTotalPercent(insiderPercentSum + smartWalletPercentSum - overlapPercentSum);

        const generateColorMap = (
            data: DataType[],
            key: keyof DataType,
            updateKey: 'NewMouseAddress' | 'TxRelative',
            checkLength: number
        ) => {
            const countMap: { [key: string]: number } = {};
            const firstOccurrenceMap: { [key: string]: boolean } = {}; // 用来记录第一个相同项是否已经处理

            const newDataSource = data.map(item => {
                const value = item[key] as string;

                // 统计出现次数
                if (countMap[value]) {
                    countMap[value]++;
                } else {
                    countMap[value] = 1;
                    firstOccurrenceMap[value] = false; // 第一次出现时，先标记为未处理
                }

                // 如果有超过1个相同项时，处理第一个和后续所有重复项
                if (countMap[value] > 1) {
                    if (!firstOccurrenceMap[value]) {
                        // 第一次相同的项，先处理
                        // data.find(i => i[key] === value)![updateKey] = true;
                        firstOccurrenceMap[value] = true; // 标记为已经处理
                    }

                    // 当前项也需要更新
                    // item[updateKey] = true;
                }

                // AddressTag不为空则TxRelative为false
                if (item.AddressTag !== "") {
                    item.TxRelative = false;
                }

                // 空值处理
                if (item.SolSource === "") {
                    item.TxRelative = false;
                }
                if (item.SplSource === "") {
                    item.NewMouseAddress = false;
                }

                return item;
            });

            if (!_.isEqual(newDataSource, dataSource)) {
                setDataSource(newDataSource);
            }

            const colorMap: { [key: string]: string } = {};
            const uniqueValues = Object.keys(countMap).filter(value => countMap[value] > 1);
            uniqueValues.forEach((value, index) => {
                colorMap[value] = `hsl(${index * (360 / uniqueValues.length)}, 70%, 50%)`;
            });
            return colorMap;
        };

        // 生成颜色映射并进行录处理
        setSolSourceColors(generateColorMap(dataSource, 'SolSource', 'TxRelative', 20));
        setSplSourceColors(generateColorMap(dataSource, 'SplSource', 'NewMouseAddress', 20));

    }, [dataSource]);

    // 盈利(SOL)排序逻辑
    const handleSortPnl = () => {
        if (isSortedByPnl) {
            // 恢复原始顺序
            setDataSource(originalDataSource);
        } else {
            // 按盈利降序排序
            const sortedData = [...dataSource].sort((a, b) => parseFloat(b.TotalPnl) - parseFloat(a.TotalPnl));
            setDataSource(sortedData);
        }
        setIsSortedByPnl(!isSortedByPnl); // 切换排序状态
    };

    // 盈利(SOL)排序逻辑
    const handleSortROI = () => {
        if (isSortedByROI) {
            // 恢复原始顺序
            setDataSource(originalDataSource);
        } else {
            // 按盈利降序排序
            const sortedData = [...dataSource].sort((a, b) => parseFloat(b.ROI) - parseFloat(a.ROI));
            setDataSource(sortedData);
        }
        setIsSortedByROI(!isSortedByROI); // 切换排序状态
    };

    const toggleSolSourceVisibility = () => {
        setHideSingleSolSource(!hideSingleSolSource);
    };

    const toggleSplSourceVisibility = () => {
        setHideSingleSplSource(!hideSingleSplSource);
    };

    const fetchGroupList = async () => {
        try {
            const response = await axios.get(`${apiUrl}/core/v1/getGroupList`, {
                headers: {
                    'Auth-Key': localStorage.getItem('authToken'),
                },
            });

            if (response.status === 200) {
                // 确保返回的数据是数组，并且每个项目都有 key 属性
                const formattedData = Array.isArray(response.data.data)
                    ? response.data.data.map((item: any, index: number) => ({
                        ...item,
                        key: item.id || index.toString() // 使用 id 作为 key，如果没有 id 则使用索引
                    }))
                    : [];

                setGroupList(formattedData);
            }
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                message.error('Token错误，请重新输入');
                localStorage.removeItem('authToken');
                showModal2();
            } else {
                message.error('获取分组列表失败');
                console.error('Fetch group list error:', error);
            }
        }
    };

    const handleOpenGroupModal = async () => {
        await fetchGroupList(); // 获取最新的分组列表
        setIsGroupModalVisible(true);
    };

    const handleDeleteGroup = async (groupId: string) => {
        try {
            const response = await axios.delete(`${apiUrl}/core/v1/deleteGroup`, {
                params: { groupId },
                headers: {
                    'Auth-Key': localStorage.getItem('authToken'),
                },
            });

            if (response.status === 200) {
                message.success('删除分组成功');
                await fetchGroupList(); // 重新获取分组列表
            }
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                message.error('Token错误，请重新输入');
                localStorage.removeItem('authToken');
                showModal2();
            } else {
                message.error('删除分组失败');
                console.error('Delete group error:', error);
            }
        }
    };

    // 修改handleEditGroup函数
    const handleEditGroup = async (groupId: string) => {
        const group = groupList.find(g => g.id === groupId);
        if (group) {
            setCurrentGroup({
                ...group,
                addresses: group.addresses || ['']
            });
            setIsEditing(true);
            setEditingGroupId(groupId);
            setIsAddGroupModalVisible(true);
        }
    };

    // 修改handleAddGroup函数为handleSaveGroup
    const handleSaveGroup = async () => {
        if (currentGroup.tag && currentGroup.addresses.some(addr => addr)) {
            try {
                const endpoint = isEditing ? 'updateGroup' : 'addGroup';
                const payload = isEditing ? {
                    groupId: editingGroupId,
                    tag: currentGroup.tag,
                    addresses: currentGroup.addresses.filter(addr => addr)
                } : {
                    tag: currentGroup.tag,
                    addresses: currentGroup.addresses.filter(addr => addr)
                };

                const response = await axios.post(`${apiUrl}/core/v1/${endpoint}`, payload, {
                    headers: {
                        'Auth-Key': localStorage.getItem('authToken'),
                    },
                });

                if (response.status === 200) {
                    message.success(isEditing ? '更新分組成功' : '添加分組成功');
                    await fetchGroupList();
                    setCurrentGroup({ addresses: [''], tag: '' });
                    setIsAddGroupModalVisible(false);
                    setIsEditing(false);
                    setEditingGroupId('');
                }
            } catch (error: any) {
                if (error.response && error.response.status === 401) {
                    message.error('Token错误，请重新输入');
                    localStorage.removeItem('authToken');
                    showModal2();
                } else {
                    message.error(isEditing ? '更新分組失敗' : '添加分組失敗');
                    console.error('Save group error:', error);
                }
            }
        }
    };

    const columns = [
        {
            title: '序號',
            dataIndex: 'No',
            key: 'No',
            width: 70,
        },
        {
            title: '地址',
            dataIndex: 'Address',
            key: 'Address',
            width: 120,
            render: (text: string, record: { AddressTag?: string }) => (
                <span>
                    {record.AddressTag ? record.AddressTag : formatAddress(text)} {/* 显示 AddressTag 或格式化的 Address */}
                    {text && text.length >= 14 && (
                        <Button
                            type="text"
                            icon={<CopyOutlined />}
                            size="small"
                            onClick={() => handleCopy(text)} // 复制完整的 Address
                            style={{ marginLeft: 8 }}
                        />
                    )}
                </span>
            ),
        },
        {
            title: '當前持倉佔比',
            dataIndex: 'HoldPercent',
            key: 'HoldPercent',
            width: 120,
        },
        {
            title: '買入平均成本(U)',
            dataIndex: 'AvgBuyPrice',
            key: 'AvgBuyPrice',
            width: 120,
            //render: (value: number) => formatNumber(value), // 调用 formatNumber 方法格式化
            render: (value: number, record: DataType) => {
                // 池子不显示
                if (value == 0) {
                    return ''
                } else {
                    return formatNumber(value);
                }
            },
        },
        {
            title: '加權平均成本',
            dataIndex: 'WeightedAverageCost',
            key: 'WeightedAverageCost',
            width: 120,
            render: (value: number, record: DataType) => {
                // 池子不显示
                if (record.AvgBuyPrice == 0 && record.TotalPnl === '0') {
                    return ''
                } else if (value !== 0) {
                    return formatNumber(value);
                }
            },
        },
        {
            title: '最早買入時間',
            dataIndex: 'FirstPurchaseTime',
            key: 'FirstPurchaseTime',
            width: 140,
        },
        {
            title: '買賣金額(SOL)',
            dataIndex: 'BuySolAmt',
            key: 'BuySolAmt',
            width: 120,
            render: (text: any, record: DataType) => (
                <span>{record.BuySolAmt} / {record.SelSolAmt}</span>
            ),
        },
        {
            title: (
                <div>
                    盈利(SOL)
                    <Button size="small" onClick={handleSortPnl} style={{ marginLeft: 8 }}>
                        {isSortedByPnl ? '恢復順序' : '盈利排序'}
                    </Button>
                </div>
            ),
            dataIndex: 'TotalPnl',
            key: 'TotalPnl',
            width: 140,
        },
        {
            title: (
                <div>
                    ROI
                    <Button size="small" onClick={handleSortROI} style={{ marginLeft: 8 }}>
                        {isSortedByROI ? '恢復順序' : '盈利排序'}
                    </Button>
                </div>
            ),
            dataIndex: 'ROI',
            key: 'ROI',
            width: 140,
        },
        {
            title: (
                <div>
                    SOL來源
                    <Button size="small" onClick={toggleSolSourceVisibility} style={{ marginLeft: 8 }}>
                        {hideSingleSolSource ? '顯示全部' : '隱藏單獨'}
                    </Button>
                </div>
            ),
            dataIndex: 'SolSource',
            key: 'SolSource',
            width: 120,
            render: (text: string, record: { SolSourceTag: string }) => {
                const color = solSourceColors[text];
                if (hideSingleSolSource && !color) {
                    return null;
                }

                // 判断 AddressTag 是否存在
                const displayText = record.SolSourceTag ? record.SolSourceTag : formatAddress(text);

                return (
                    <span style={{ color: color ? color : 'inherit' }}>
                        {displayText} {/* 使用 formatAddress 方法简化显示 */}
                        {text && text.length >= 14 && (
                            <Button
                                type="text"
                                icon={<CopyOutlined />}
                                size="small"
                                onClick={() => handleCopy(text)} // 复制完整的 SolSource
                                style={{ marginLeft: 8 }}
                            />
                        )}
                    </span>
                );
            },
        },
        {
            title: (
                <div>
                    SPL來源
                    <Button size="small" onClick={toggleSplSourceVisibility} style={{ marginLeft: 8 }}>
                        {hideSingleSplSource ? '顯示全部' : '隱藏單獨'}
                    </Button>
                </div>
            ),
            dataIndex: 'SplSource',
            key: 'SplSource',
            width: 120,
            render: (text: string, record: DataType) => {
                const color = splSourceColors[text];
                if (hideSingleSplSource && !color) {
                    return null;
                }
                return (
                    <Tooltip title={<span>共轉入: {record.TransferInAmount} <br />共轉出: {record.TransferOutAmount} <br />凈轉入: {record.NetTransferredAmount} <br />溯源均價(S): {formatNumber(record.TraceableAveragePrice)}</span>}>
                        <span style={{ color: color ? color : 'inherit' }}>
                            {formatAddress(text)} {/* 使用 formatAddress 方法简化显示 */}
                            {text && (
                                <Button
                                    type="text"
                                    icon={<CopyOutlined />}
                                    size="small"
                                    onClick={() => handleCopy(text)} // 复制完整的 SplSource
                                    style={{ marginLeft: 8 }}
                                />
                            )}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            title: (
                <div>
                    標簽
                    <Button size="small" style={{ marginLeft: 8 }} onClick={showModal}>添加黑名单</Button>
                </div>
            ),
            key: 'tags',
            width: 200,
            render: (record: DataType) => {
                const tags = [];
                if (record.New) tags.push(<Tag color="blue" key={`${record.key}-new`}>新地址</Tag>);
                if (record.Dev) tags.push(<Tag color="gray" key={`${record.key}-dev`}>Dev</Tag>);
                if (record.PoolTag) tags.push(<Tag color="gray">{(record.PoolName)}</Tag>);
                if (record.SmartMoney) tags.push(<Tag color="green" key={`${record.key}-dev`}>聰明錢</Tag>);
                if (record.NewMouseAddress && record.SplSource !== "") tags.push(<Tag color="orange">老鼠倉</Tag>);
                if (record.TxRelative && record.SolSource !== "") tags.push(<Tag color="purple">轉賬關聯</Tag>);
                // if (record.AddressTag !== "") tags.push(<Tag color="red">{record.AddressTag}</Tag>);
                return <>{tags}</>;
            },
        }
    ];

    return (
        <div>
            <Input
                placeholder="輸入合約地址"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                style={{ width: 300, marginRight: 10 }}
            />
            <Button type="primary" onClick={handleSearch}>搜索</Button>
            <Button type="primary" onClick={handleUpload} style={{ marginLeft: 10 }}>疑似內幕收集</Button>
            <Button
                type="primary"
                onClick={handleOpenGroupModal}
                style={{ marginLeft: 10 }}
            >
                分組設置
            </Button>
            {errorMessage && (
                <div style={{ color: 'red', marginTop: 10 }}>
                    {errorMessage}
                </div>
            )}
            <div>
                <div>新地址數量：{(newNum === 0) ? 0 : newNum}</div>
                <div>新地址持倉佔比：{(newNum === 0) ? 0 : newPercent.toFixed(2)}%</div>
                <div>聰明錢數量：{(smartWalletNum)}</div>
                <div>聰明錢持倉佔比：{(smartWalletNum === 0) ? 0 : smartWalletPercent.toFixed(2)}%</div>
                <div>內幕地址數量：{(insiderNum)}</div>
                <div>內幕地址持倉佔比：{(insiderNum === 0) ? 0 : insiderPercent.toFixed(2)}%</div>
                <div>合計持倉佔比：{totalPercent.toFixed(2)}%</div>
                {groupStats.map((group, index) => (
                    <div key={index}>{group.groupName}持倉佔比：{group.totalPercent.toFixed(2)}%</div>
                ))}
            </div>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                className="custom-table"
            />
            <Modal
                title="添加黑名單"
                open={isModalVisible}
                onOk={handleAddToBlacklist}
                onCancel={handleCancel}
            >
                <Form>
                    <Form.Item label="目標地址">
                        <Input
                            value={targetAddress}
                            onChange={(e) => setTargetAddress(e.target.value)}
                            placeholder="請輸入目標地址"
                        />
                    </Form.Item>

                    <Form.Item label="类型选择" required>
                        <Radio.Group onChange={(e) => setBlackListType(e.target.value)} value={blackListType} defaultValue="exchange">
                            <Radio value="exchange">交易所</Radio>
                            <Radio value="pool">池子</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="黑名单类型">
                        <Checkbox
                            checked={ignoreAll}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                setIgnoreAll(checked);
                                setIgnoreAllTags(checked);
                                setIgnoreAllSources(checked);
                                setIgnoreNew(checked);
                                setIgnoreTx(checked);
                                setIgnoreMouse(checked);
                                setIgnoreSolSource(checked);
                                setIgnoreSplSource(checked);
                            }}
                        >
                            全部
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Checkbox
                            checked={ignoreAllTags}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                setIgnoreAllTags(checked);
                                setIgnoreNew(checked);
                                setIgnoreTx(checked);
                                setIgnoreMouse(checked);
                            }}
                        >
                            忽略任何标签
                        </Checkbox>
                        <Checkbox
                            checked={ignoreAllSources}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                setIgnoreAllSources(checked);
                                setIgnoreSolSource(checked);
                                setIgnoreSplSource(checked);
                            }}
                        >
                            忽略任何来源判断
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Checkbox
                            checked={ignoreNew}
                            onChange={(e) => setIgnoreNew(e.target.checked)}
                        >
                            忽略新地址
                        </Checkbox>
                        <Checkbox
                            checked={ignoreTx}
                            onChange={(e) => setIgnoreTx(e.target.checked)}
                        >
                            忽略转账关联
                        </Checkbox>
                        <Checkbox
                            checked={ignoreMouse}
                            onChange={(e) => setIgnoreMouse(e.target.checked)}
                        >
                            忽略老鼠仓
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={ignoreSolSource}
                            onChange={(e) => setIgnoreSolSource(e.target.checked)}
                        >
                            忽略SOL来源
                        </Checkbox>
                        <Checkbox
                            checked={ignoreSplSource}
                            onChange={(e) => setIgnoreSplSource(e.target.checked)}
                        >
                            忽略SPL来源
                        </Checkbox>
                    </Form.Item>

                    <Form.Item label="備註信息">
                        <Input
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            placeholder="請輸入備註信息"
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="请输入Token"
                open={isModalVisible2}
                onOk={handleOk}
                onCancel={handleCancel2}
                centered
                closable={false} // 隐藏右上角的关闭按钮
                maskClosable={false} // 点击蒙层不会关闭Modal
                styles={{ mask: { backgroundColor: 'rgba(0, 0, 0, 1)' } }} // 调整遮罩层背景颜色为黑色
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk}>
                        确认
                    </Button>
                ]}
            >
                <Input
                    placeholder="输入Token"
                    value={inputToken}
                    onChange={(e) => setInputToken(e.target.value)}
                />
            </Modal>
            <Modal
                title="分組管理"
                open={isGroupModalVisible}
                onCancel={() => setIsGroupModalVisible(false)}
                footer={[
                    <Button key="add" type="primary" onClick={() => setIsAddGroupModalVisible(true)}>
                        添加分組
                    </Button>,
                    <Button key="close" onClick={() => setIsGroupModalVisible(false)}>
                        關閉
                    </Button>
                ]}
            >
                <Table
                    dataSource={groupList}
                    columns={[
                        {
                            title: '標籤',
                            dataIndex: 'tag',
                            key: 'tag'
                        },
                        {
                            title: '地址',
                            dataIndex: 'addresses',
                            key: 'addresses',
                            render: (addresses: string[]) => addresses.join(', ')
                        },
                        {
                            title: '操作',
                            key: 'action',
                            render: (record) => (
                                <>
                                    <Button
                                        type="link"
                                        onClick={() => handleDeleteGroup(record.id)}
                                    >
                                        刪除
                                    </Button>
                                    <Button
                                        type="link"
                                        onClick={() => handleEditGroup(record.id)}
                                    >
                                        編輯
                                    </Button>
                                </>
                            )
                        }
                    ]}
                    pagination={false}
                />
            </Modal>
            <Modal
                title={isEditing ? "編輯分組" : "添加分組"}
                open={isAddGroupModalVisible}
                onOk={handleSaveGroup}
                onCancel={() => {
                    setIsAddGroupModalVisible(false);
                    setCurrentGroup({ addresses: [''], tag: '' });
                    setIsEditing(false);
                    setEditingGroupId('');
                }}
            >
                <Form layout="vertical">
                    <Form.Item label="標籤">
                        <Input
                            value={currentGroup.tag}
                            onChange={e => setCurrentGroup({
                                ...currentGroup,
                                tag: e.target.value
                            })}
                        />
                    </Form.Item>
                    <Form.Item label="地址">
                        {currentGroup.addresses.map((address, index) => (
                            <div key={index} style={{ marginBottom: 8, display: 'flex' }}>
                                <Input
                                    value={address}
                                    onChange={e => {
                                        const newAddresses = [...currentGroup.addresses];
                                        newAddresses[index] = e.target.value;
                                        setCurrentGroup({
                                            ...currentGroup,
                                            addresses: newAddresses
                                        });
                                    }}
                                    style={{ marginRight: 8 }}
                                />
                                <Button
                                    type="link"
                                    onClick={() => {
                                        const newAddresses = [...currentGroup.addresses];
                                        newAddresses.splice(index, 1);
                                        setCurrentGroup({
                                            ...currentGroup,
                                            addresses: newAddresses
                                        });
                                    }}
                                    disabled={currentGroup.addresses.length === 1}
                                >
                                    刪除
                                </Button>
                            </div>
                        ))}
                        <Button
                            type="dashed"
                            onClick={() => setCurrentGroup({
                                ...currentGroup,
                                addresses: [...currentGroup.addresses, '']
                            })}
                            block
                        >
                            添加地址
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default TableComponent;